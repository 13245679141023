<template>
    <div id="modalEmailComposer" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="modalEmailComposer" aria-hidden="true" data-keyboard="false" data-backdrop="static">
      <div class="modal-dialog modal-dialog-centered modal-form-size" role="document">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="card card-plain">
              <div class="card-header pb-0 text-left">
                <h4 class="font-weight-bolder text-info text-gradient">Email Composer</h4>
              </div>
              <div class="card-body">
                <div class="col-12 col-sm-12 mb-2">
                  <label>To Emails</label>
                  <div class="form-control email-tag-container" @click="focusEmailInput">
                    <span v-for="(email, index) in toEmails" :key="index" class="badge bg-info me-1">
                      {{ email }}
                      <button type="button" class="btn-close ms-2" @click="removeEmail(index)"></button>
                    </span>
                    <input
                      ref="emailInput"
                      v-model="newEmail"
                      type="email"
                      class="email-input"
                      placeholder="Type email and press Enter"
                      @keyup.enter="addEmail"
                    />
                  </div>
                  <ErrorMessage class="text-danger" name="toEmails" />
                </div>
                <div class="col-12 col-sm-12 mb-2">
                  <label>CC Emails</label>
                  <div class="form-control email-tag-container" @click="focusCcEmailInput">
                    <span v-for="(email, index) in ccEmails" :key="index" class="badge bg-info me-1">
                      {{ email }}
                      <button type="button" class="btn-close ms-2" @click="removeCcEmail(index)"></button>
                    </span>
                    <input
                      ref="emailCcInput"
                      v-model="ccEmail"
                      type="email"
                      class="email-input"
                      placeholder="Type email and press Enter"
                      @keyup.enter="addCcEmail"
                    />
                  </div>
                  <ErrorMessage class="text-danger" name="toEmails" />
                </div>
                <div class="col-12 col-sm-12 mb-2">
                  <label>Subject</label>
                  <div class="form-control email-tag-container">
                    <input
                      v-model="subject"
                      type="text"
                      class="email-input"
                    />
                  </div>
                </div>
                <div id="quill-editor" style="height: 200px;"></div>
                <!-- <div class="col-12 col-sm-12 mb-2">
                  <label>Attachments</label>
                  <input type="file" multiple class="form-control" @change="handleFileUpload" />
                  <ul class="mt-2">
                    <li v-for="(file, index) in attachments" :key="index">
                      {{ file.name }} <button class="btn-close btn-sm btn-close-black" @click="removeAttachment(index)"></button>
                    </li>
                  </ul>
                </div> -->
              </div>
              <div class="card-footer">
                <button class="btn btn-primary primary-button" @click="send">Send</button>
                <button class="btn btn-secondary" data-bs-dismiss="modal">Close</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ErrorMessage } from 'vee-validate';
import * as yup from 'yup';
export default {
    name: "EmailComposer",
    components: {
      ErrorMessage
    },
    props:{
      show:{
        type: Boolean,
        default: false
      },
      toEmail: {
        type: String,
        default: ''
      }
    },
    emits: ['close-modal', 'send'],
    data() {
        return {
          subject: '',
          newEmail: '',
          ccEmail: '',
          toEmails: [],
          ccEmails: [],
          attachments: [],
          createPostSchema: yup.object({
        toEmails: yup
          .array()
          .min(1, "At least one email is required")
          .of(yup.string().email("Invalid email format"))
          .required("Email is required"),
      })
        }
    },
    watch: {
      toEmail(newEmail) {
        if (newEmail) {
            this.toEmails = [newEmail]; // Ensure the email updates correctly
        }
      }
    },
    mounted() {
     
      this.$nextTick(() => {
        // Wait until Quill is loaded
        if (window.Quill) {
          this.quill = new window.Quill("#quill-editor", {
            theme: "snow",
          });
        } else {
          console.error("Quill is not loaded. Check your CDN.");
        }
      });
    },
    methods: {
      focusCcEmailInput () {
        this.$refs.emailCcInput.focus();
      },
      async handleFileUpload(event) {
        const files = event.target.files;
        for (let file of files) {
          const base64 = await this.toBase64(file);
          this.attachments.push({ name: file.name, type: file.type, content: base64 });
        }
      },
      toBase64(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file);
          reader.onload = () => resolve(reader.result);
          reader.onerror = error => reject(error);
        });
      },
      removeAttachment(index) {
        this.attachments.splice(index, 1);
      },
      addEmail() {
        if (this.newEmail.trim() !== "" && this.validateEmail(this.newEmail)) {
          this.toEmails.push(this.newEmail.trim());
          this.newEmail = "";
        }
      },
      addCcEmail() {
        if (this.ccEmail.trim() !== "" && this.validateEmail(this.ccEmail)) {
          this.ccEmails.push(this.ccEmail.trim());
          this.ccEmail = "";
        }
      },
      removeEmail(index) {
        this.toEmails.splice(index, 1);
      },
      removeCcEmail(index) {
        this.ccEmails.splice(index, 1);
      },
      validateEmail(email) {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
      },
      focusEmailInput() {
        this.$refs.emailInput.focus();
      },

      closeModal () {
        this.toEmails = []
        this.$emit('close-modal')
      },
      send () {
        const emailData = {
          subject: this.subject,
          toEmails: this.toEmails,
          ccEmails: this.ccEmails,
          message: this.quill.root.innerHTML,
          attachments: this.attachments,
        };
        this.$emit("send", emailData);
      }
    }
  };
</script>
<style scoped>
.primary-button {
    background-color: #0fdf14;
    margin-right: 5px;
} 
.btn-close {
  filter: invert(1); /* Inverts the color, making white buttons black */
}
.email-tag-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 5px;
  min-height: 40px;
}

.email-input {
  border: none;
  outline: none;
  flex-grow: 1;
  min-width: 120px;
  background: transparent;
}

.badge {
  display: flex;
  align-items: center;
  padding: 2px 10px;
  text-transform: none; /* Prevents automatic capitalization */
  font-size: 14px; /* Keeps the font size normal */
}
</style>