<template>
    <div class="card">
        <div class="table-responsive">
            <table class="table align-items-center mb-0">
                <thead>
                    <tr>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Id
                        </th>
                        <th class="text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Name
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Email
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Active Status
                        </th>
                        <th class="text-center text-uppercase text-secondary text-xxs font-weight-bolder opacity-7">
                            Points
                        </th>
                        <th class="text-secondary opacity-7">

                        </th>
                    </tr>
                </thead>
                <tbody>
                    <tr v-for="(user) in userList" :key="user.id">
                        <td class="align-middle text-center">
                            <span class="text-secondary text-sm">{{ user.id }}</span>
                        </td>
                        <td>
                            <div class="d-flex px-2 py-1">
                                <soft-avatar :img="img2" class="me-3" alt="avatar image" border-radius="lg" />
                                <div class="d-flex flex-column justify-content-center">
                                    <h6 class="mb-0 text-sm">{{ user.fullName }}</h6>
                                </div>
                            </div>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ user.email }}</p>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <span :class=" user.activeStatus === '1' ? 'badge badge-sm badge-success' : 'badge badge-sm badge-secondary'">{{ user.activeStatus === '1' ? 'Active' : 'Deactivate'}}</span>
                        </td>
                        <td class="align-middle text-center text-sm">
                            <p class="text-secondary mb-0 text-sm">{{ user.points }}</p>
                        </td>
                        <td class="align-middle">
                            <button class="btn btn-outline-secondary btn-sm mb-0" data-toggle="tooltip" data-original-title="View More" @click="editClick(user.id)">
                                <span class="font-weight-bold text-xs">View More</span>
                            </button>
                            <button class="btn btn-outline-primary btn-sm mb-0 ms-2" data-bs-toggle="modal" data-bs-target="#modalEmailComposer" size="sm"
                            @click="openComposer(user.email)">
                                <i class="fas fa-envelope fa-lg"></i>
                            </button>
                        </td>
                        
                    </tr>
                </tbody>
            </table>
            <email-composer ref="emailComposer" :to-email="toEmail" @send="sendEmail"/>
        </div>
    </div>
</template>
  
<script>
import SoftAvatar from "@/components/SoftAvatar.vue";
import img1 from "../../../../assets/img/team-1.jpg";
import img2 from "../../../../assets/img/team-2.jpg";
import img3 from "../../../../assets/img/team-3.jpg";
import img4 from "../../../../assets/img/team-4.jpg";
import img5 from "../../../../assets/img/team-5.jpg";
import EmailComposer from "@/components/Email/EmailComposer";
import EmailService from "../../../../services/api/email/EmailService";
export default {
    name: "UserListTable",
    components: {
        SoftAvatar,
        EmailComposer
    },
    props:{
        userList:{
            type: Array,
            default () {
                return []
            }
        }
    },
    data() {
        return {
            img1,
            img2,
            img3,
            img4,
            img5,
            showEmailComposer: false,
            toEmail: ''
        };
    },
    methods: {
        editClick (id) {
            this.$router.push({ name: 'UserProfileInfo', params: { id: id } })
        },

        async sendEmail (data) {
            try {
                this.$swal.showLoading();
                const emailService = new EmailService()
                await emailService.send(data)

                this.$swal({
                    icon: "success",
                    title: "Success",
                    text: "Successfully sent!",
                    type: 'success-message',
                })
            } catch (error) {
                let msgAlert = ''
                if (error.response.status === 422) {
                    msgAlert = error.response.data.message
                } else if((error.response.status === 403)) {
                    msgAlert = error.response.data.message
                } else {
                    msgAlert = 'Something went wrong...!'
                }

                this.$swal({
                    text: msgAlert,
                    icon: "error",
                    customClass: {
                    confirmButton: "btn bg-gradient-success",
                    },
                    buttonsStyling: false,
                })
            } finally {
                const closeButton = this.$refs.emailComposer.$el.querySelector('[data-bs-dismiss="modal"]');
                this.$swal.close()
                if (closeButton) {
                    closeButton.click();
                }
            }
        },
        openComposer(email) {
            this.toEmail = email
            this.showEmailComposer = true
            
        },
    }
};
</script>
  