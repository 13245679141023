import BaseAPI from '../BaseAPI'

/**
 * Gnome Class that can be use to connect the email apis
 */

export default class EmailService extends BaseAPI {
  send (payload) {
    return new Promise((resolve, reject) => {
      const pathArr = ['send-emails']

      this.post(payload, pathArr).then((response) => {
        resolve(response)
      }).catch((err) => {
        reject(err)
      })
    })
  }
}